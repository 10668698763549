import {Fragment} from 'react';
import {FaFacebookF, FaInstagram, FaLinkedin, FaTwitter} from 'react-icons/fa';
import {NavLink} from 'react-router-dom';
import './Footer.css';
import {isUserLoggedIn} from '../../auth/utiles';
import {useAppSelector} from '../../hooks/hooks';
import {useTranslation} from 'react-i18next';

function Footer() {
  const {t} = useTranslation();
  const isCons = localStorage.getItem('isCons');
  const isLoggedIn = isUserLoggedIn();
  const consStatus = useAppSelector(state => state.user.consStatus);
  return (
    <Fragment>
      <div className="footer" id="footer">
        <div className="container">
          <div className="row justify-content-between">
            <div className="FooterLogo">
              <img src="/nurabi.png" alt="footerImg" />
            </div>

            <div className="col-md-3 mb-4">
              <h4 className="FooterTitle">{t('About Nurabi')}</h4>
              <p className="aboutNurabi">{t('footerAboutDesc')}</p>
            </div>

            <div className="col-md-2 mb-4">
              <h4 className="FooterTitle">{t('Site Map')}</h4>
              <div className="Sitemap">
                {/*
                <p className="SiteLink">
                  <NavLink to="/consultants/get-teachers">
                    {t('Teachers')}
                  </NavLink>
                </p>
                */}
                <p className="SiteLink">
                  <NavLink to="/consultants/get-consultants">
                    {t('Consultations')}
                  </NavLink>
                </p>
                <p className="SiteLink">
                  <NavLink to="/user/initiatives/">{t('Initiatives')}</NavLink>
                </p>
                <p className="SiteLink">
                  <NavLink to="/user/trackings/list/">{t('Follow-up Systems')}</NavLink>
                </p>
                <p className="SiteLink">
                  <NavLink to="/courses">{t('Courses')}</NavLink>
                </p>
                <p className="SiteLink">
                  <NavLink to="/bank-questions">{t('Bank Questions')}</NavLink>
                </p>
                {(isCons !== 'true' || isCons == null || !isCons) &&
                  isLoggedIn &&
                  (consStatus == null || consStatus == 'rejected') && (
                    <p className="SiteLink">
                      <NavLink to="/user/consultation/request">
                        {t('join')}
                      </NavLink>
                    </p>
                  )}
              </div>
            </div>

            <div className="col-md-2 mb-4">
              <h4 className="FooterTitle">{t('Links')}</h4>
              <div className="Sitemap">
                <p className="SiteLink">
                  <NavLink to="https://play.google.com/store/apps/details?id=com.nurabi">
                    {t('App Store')}
                  </NavLink>
                </p>
                <p className="SiteLink">
                  <NavLink to="https://apps.apple.com/eg/app/nurabi-app/id1593607736">
                    {t('Play Store')}
                  </NavLink>
                </p>
                {!isLoggedIn && (
                  <div>
                    <p className="SiteLink mb-1">
                      <NavLink to="/login">{t('Login')}</NavLink>
                    </p>
                    <p className="SiteLink mb-1">
                      <NavLink to="/sign-up">{t('Register')}</NavLink>
                    </p>
                  </div>
                )}
                <p className="SiteLink">
                  <NavLink to="/contact-us">{t('Contact Us')}</NavLink>
                </p>
                <p className="SiteLink">
                  <NavLink to="/terms-and-conditions">
                    {t('Terms And Conditions')}
                  </NavLink>
                </p>
              </div>
            </div>

            <div className="col-md-2 mb-4">
              <h4 className="FooterTitle">{t('Download App')}</h4>
              <div className="Sitemap">
                <img
                  src="/qr2.jpeg"
                  className="mt-1"
                  style={{width: '100%', borderRadius: '15px', width: '100px'}}
                  alt="img"
                />
                {/* <p className='SiteLink'><NavLink to="/login">Login</NavLink></p>
                <p className='SiteLink'><NavLink to="/register">Register</NavLink></p> */}
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <h4 className="FooterTitle">{t('Get In Touch With Us')}</h4>
              <div className="Sitemap">
                {/* <NavLink to="/">
                  <img
                    src="/contact/phone.png"
                    className="ContactImg"
                    alt="phone"
                  />
                </NavLink> */}
                <NavLink to="https://wa.me/+16478059910">
                  <img
                    src="/contact/whatsapp.png"
                    className="ContactImg"
                    alt="whatsapp"
                  />
                </NavLink>
                <a href="mailto:info@nurabi.net" target="_blank">
                  <img
                    src="/contact/mail.png"
                    className="ContactImg"
                    alt="mail"
                  />
                </a>
                {/* <NavLink to="/">
                  <img
                    src="/contact/web.png"
                    className="ContactImg"
                    alt="web"
                  />
                </NavLink> */}
                {/* <NavLink to="/">
                  <img
                    src="/contact/location.png"
                    className="ContactImg"
                    alt="location"
                  />
                </NavLink> */}
              </div>
            </div>

            <div className="col-md-4 SocialIcons" style={{textAlign: 'right'}}>
              <FaFacebookF
                className="SocialIcon"
                size={35}
                onClick={() => {
                  window.open(
                    'https://www.facebook.com/NurabiApp.EN',
                    '_blank',
                  );
                }}
              />
              <FaInstagram
                className="SocialIcon"
                size={35}
                onClick={() => {
                  window.open(
                    'https://www.instagram.com/nurabiapp.en/',
                    '_blank',
                  );
                }}
              />
              <FaLinkedin
                className="SocialIcon"
                size={35}
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/company/nurabiapp',
                    '_blank',
                  );
                }}
              />
              <FaTwitter
                className="SocialIcon"
                size={35}
                onClick={() => {
                  window.open('https://twitter.com/NurabiApp', '_blank');
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="CopyRight">{t('Copyright © belongs to Nurabi')}</div>
    </Fragment>
  );
}

export default Footer;
